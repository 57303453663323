<template>
  <v-card elevation="0" style="padding: 0 30px;height: 100%;display: flex;align-items: stretch;height: 100vh;background:#9e9e9e1f">
    <v-row>
      <v-col cols="12" v-if="step < 3" >
        <p class="text-center">
          <img src="@/assets/images/logo-login.png" style="width:150px;border-radius: 5px;margin:45px auto">
        </p>
        <div v-if="step == 1">
          <v-text-field v-model="user" outlined label="نام کاربری را وارد کنید" type="text" dir="ltr" ></v-text-field>
          <v-text-field v-model="pass" outlined label="رمز را وارد کنید" type="password" dir="ltr" ></v-text-field>
          <v-btn @click="login" :loading="loading" depressed color="primary" height="50"  style="width: 100%;">ورود</v-btn>
        </div>
        <div v-if="step == 2">
          <p class="bold">کد تایید را وارد نمایید</p>
          <v-otp-input length="5" v-model="code" type="number" style="direction:ltr"></v-otp-input>
          <v-btn @click="verifyCheck" :loading="loading" depressed color="primary" height="50" style="width: 100%"> تایید و وورد </v-btn>
          <p v-if="timer > 1" style="text-align: center;padding: 15px 10px"> <span v-text="timer"></span> ثانیه مانده تا ارسال مجدد </p>
          <p @click="authCheck" v-if="timer < 1" style="text-align: center;padding: 15px 10px">  ارسال مجدد </p>
        </div>
      </v-col>
      <v-col cols="12">
        <div v-if="step == 3" class="text-center" style="width: 90%;background: #eee;border-radius: 15px;padding: 20px;margin: auto">
          <v-avatar>
            <img src="@/assets/images/avatar.png" width="100px"/>
          </v-avatar>
          <p style="font-weight: bold;">لطفا فرم زیر را تکمیل کنید</p>
          <v-text-field v-model="param.fname" outlined label="نام" rounded ></v-text-field>
          <v-text-field v-model="param.lname" outlined label="نام خانوادگی" rounded ></v-text-field>
          <v-text-field v-model="param.code_melli" outlined label="شماره ملی" type="number" rounded ></v-text-field>

          <v-row>
            <v-col cols="6">
              <v-autocomplete
                  v-model="param.state"
                  :items="items_state"
                  item-text="label"
                  outlined
                  dense
                  label=" استان *"
                  rounded

              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                  v-model="param.city"
                  :items="items_city"
                  item-text="label"
                  outlined
                  dense
                  label=" شهر *"
                  rounded
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn rounded @click="register" :loading="loading" depressed color="primary" height="50" style="width: 100%"> تکمیل ثبت نام </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import profileImage from '@/assets/images/avatar.png';
import logo2 from '@/assets/images/logo-login.png';
import {mapMutations} from 'vuex';
import axios from "axios";
export default {
  name: 'login',
  components:{mapMutations},
  data() {
    return {
      user:'',
      pass:'',
      mobile:'09',
      verify:'',
      code:'',
      step : 1,
      loading:false,
      timer:0,
      resend:0,
      items_state:[],
      items_city:[],
      user_exist:0,
      param:{
        state:0,
        city:0,
        fname:'',
        lname:'',
        code_melli:'',
      }
    }
  },
  mounted() {
    //this.getState();
    this.helperLoading('off')
  },
  computed:{
  },
  methods:{
    ...mapMutations(["setToken"]),
    setAltImg(event) {
      event.target.src = logo2
    },
    getState(){
      let self = this;
      this.helperGet('get-state',{}).then(function (r) {
        self.items_state = r.data.map(function (item) {
          item.value = item.key;
          return item;
        });
      })
    },
    register(){
      let self =this;
      this.loading = true;
      let newParam ={};
      for(let k in this.param){
        let key = k.charAt(0).toUpperCase() + k.slice(1);
        newParam[key] = this.param[k];
      }
      newParam.code_melli = this.param.code_melli;
      newParam.Mobile = this.mobile;
      this.helperPost('signup',newParam).then(function (r) {
        if(r.data.result == '1'){
          r.data.loggedIn = true;
          self.setToken(r.data);
          self.$router.push({ path: '/' });
        }else{
          self.$toast.warning(r.data.msg);
        }
        self.loading = false;
      }).catch(function (e) {
        self.loading = false;
      })
    },
    authCheck(){
      let self =this;
      this.resend = 0;
      this.loading = true;
      this.helperPost('login',{Mobile:this.mobile})
          .then(r=>{
            if(r.data.result == '1'){
              self.step = 2;
              self.user_exist = r.data.user_exist;
              self.timing(60);
            }
            else{self.$toast.warning(r.data.msg);}
            self.loading = false;
          }).catch(e=>{
        self.loading = false;
      })
    },
    login(){
      let self =this;
      this.resend = 0;
      this.loading = true;

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()

      formData.append("token", self.appToken);
      formData.append("username", this.user);
      formData.append('password', this.pass);

      axios.post(this.baseUrl+'/login.aspx', formData, config)
          .then(function (r) {
            //console.log(r);
            if(r.data.data.result==="1"){
              r.data.loggedIn = true;
              self.setToken(r.data);
              self.$router.push({ path: '/' });
            }
            else
            {self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });

            }
            self.loading = false;
          });

    },
    verifyCheck(){
      let self =this;
      this.loading = true;
      this.helperPost('verify',{
        Mobile:this.mobile,
        Code:this.code,
      })
          .then((r) => {
            if(r.data.result == '1'){
              if(self.user_exist){
                r.data.loggedIn = true;
                self.setToken(r.data);
                self.$router.push({ path: '/' });
              }else{
                self.step = 3;
              }
            }
            else{self.$toast.warning(r.data.msg);}
            self.loading = false;
          }).catch(e=>{
        self.loading = false;
      })
    },
    timing(number){
      let self =this;
      self.timer = number;
      setInterval(function () {
        self.timer -- ;
        if(self.timer < 1){
          self.resend = 1;
        }
      },1000);
    },

  },
  watch:{
    'param.state':function (v) {
      let self = this;
      this.helperGet('get-city',{id:v}).then(function (r) {
        self.items_city = r.data.map(function (item) {
          item.value = item.key;
          return item;
        });
      })
    }
  }
}

</script>









